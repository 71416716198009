import "./BanPhimSo2.scss";
import React, { useState } from "react";
import DinhDangSo from "../component/DinhDangSo";
const BanPhimSo = (props) => {
    const { soMoi, setsoMoi } = props;
    const [viewBanPhimSo, setviewBanPhimSo] = useState("");
    const handleBanPhimSo = (giaTriMoi) => {
        if (giaTriMoi !== "xoa") {
            setsoMoi({
                tenSo: soMoi?.tenSo,
                giaTri: soMoi?.giaTri + giaTriMoi,
                loaiSo: soMoi?.loaiSo,
            });
        } else {
            setsoMoi({
                tenSo: soMoi?.tenSo,
                giaTri: soMoi?.giaTri?.toString()?.slice(0, -1),
                loaiSo: soMoi?.loaiSo,
            });
        }
    };
    return (
        <span>
            <span className="soBanDau" onClick={() => setviewBanPhimSo("true")}>
                <DinhDangSo
                    soDinhDang={soMoi?.giaTri}
                    donViSo={soMoi?.loaiSo}
                />
            </span>
            {viewBanPhimSo && (
                <div className="BanPhimSo-ConTaiNer">
                    <div className="soSua-banPhimSo">
                        <div className="tat-tenSo-xong">
                            <div className="tenSo">{soMoi?.tenSo}</div>
                            <div
                                onClick={() => setviewBanPhimSo()}
                                className="xong"
                            >
                                <i
                                    className="fa fa-check"
                                    style={{
                                        color: "#",
                                    }}
                                ></i>
                            </div>
                        </div>
                        <div className="soMoi">
                            <DinhDangSo
                                soDinhDang={soMoi?.giaTri}
                                donViSo={soMoi?.loaiSo}
                            />
                        </div>
                        <div className="banPhimSo">
                            <div onClick={() => handleBanPhimSo("1")}>1</div>
                            <div onClick={() => handleBanPhimSo("2")}>2</div>
                            <div onClick={() => handleBanPhimSo("3")}>3</div>
                            <div onClick={() => handleBanPhimSo("4")}>4</div>
                            <div onClick={() => handleBanPhimSo("5")}>5</div>
                            <div onClick={() => handleBanPhimSo("6")}>6</div>
                            <div onClick={() => handleBanPhimSo("7")}>7</div>
                            <div onClick={() => handleBanPhimSo("8")}>8</div>
                            <div onClick={() => handleBanPhimSo("9")}>9</div>
                            <div onClick={() => handleBanPhimSo("000")}>
                                .000
                            </div>
                            <div onClick={() => handleBanPhimSo("0")}>0</div>
                            <div onClick={() => handleBanPhimSo("xoa")}>
                                <i className="fas fa-chevron-circle-left"></i>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </span>
    );
};
export default BanPhimSo;
