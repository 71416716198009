import "./DinhDangSo.scss";

const DinhDangSo = (props) => {
    const { soDinhDang, donViSo } = props;
    const chieuRongMH = window.innerWidth;
    const handleDinhDangSo = (soDinhDang) => {
        const n = +soDinhDang;
        return n.toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "," && (a.length - i) % 3 === 0 ? "." + c : c;
        });
    };
    // phan loai don vi: thong thuong - tt, vnd,fabysa
    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="DinhDangSo-ConTaiNer">
                        {donViSo === "tt" && (
                            <div className="cssSo">
                                {handleDinhDangSo(soDinhDang)}
                            </div>
                        )}
                        {donViSo === "vnd" && (
                            <div className="cssSo">
                                {handleDinhDangSo(soDinhDang)}&#160;
                                <span className="vnd">đ</span>
                            </div>
                        )}

                        {donViSo === "fabysa" && (
                            <div className="cssSo">
                                {handleDinhDangSo(soDinhDang)}&#160;
                                <span className="fabysa">F</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="DinhDangSo-ConTaiNer">DinhDangSo</div>
                </div>
            )}
        </div>
    );
};
export default DinhDangSo;
