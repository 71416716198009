import "./ThuNghiem.scss";
import React, { useRef, useState, useEffect } from "react";
const ThuNghiem = () => {
    const chieuRongMH = window.innerWidth;
 

    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="ThuNghiem-ConTaiNer">
                        <div>Thử Nghiệm</div>
                        <div>........</div>
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="ThuNghiem-ConTaiNer">pc</div>
                </div>
            )}
        </div>
    );
};
export default ThuNghiem;
