import "./ChonDiaChi.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { registerDVHC, getDVHC, updateDVHC } from "../redux/apiRequest";
const ChonDiaChi = (props) => {
    const { thonXom, setthonXom, xa, setxa, sethuyen, huyen, settinh, tinh } =
        props;
    const dispatch = useDispatch();
    const donViHanhChinh = useSelector(
        (state) =>
            state.donViHanhChinh.donViHanhChinh.donViHanhChinh?.donViHanhChinh
    );
    useEffect(() => {
        const allDVHC = "allTinh";
        if (allDVHC && tinh && huyen && xa) {
            getDVHC(allDVHC, tinh, huyen, xa, dispatch);
        }
    }, [tinh, huyen, xa]);
    const dsTinh = donViHanhChinh?.donViHanhChinhVN;
    const dsHuyen = dsTinh?.find((item) => item?.tenTinh === tinh)?.dsHuyen;
    const dsXa = dsHuyen?.find((item) => item?.tenHuyen === huyen)?.dsXa;
    const handleChonTinh = (t) => {
        settinh(t);
        sethuyen("Quận/Huyện");
        setxa("Xã/Phường");
    };
    const handleChonHuyen = (h) => {
        sethuyen(h);
        setxa("Xã/Phường");
    };
    const handleChonXa = (x) => {
        setxa(x);
    };
    return (
        <div className="ChonDiaChi-ConTaiNer">
            <div className="tieuDe">Địa Chỉ</div>
            <div className="tinh-huyen-xa">
                <select onChange={(e) => handleChonTinh(e.target.value)}>
                    <option>{tinh}</option>
                    {dsTinh?.map((item1, index1) => {
                        return <option key={index1}>{item1?.tenTinh}</option>;
                    })}
                </select>
                <select onChange={(e) => handleChonHuyen(e.target.value)}>
                    <option>{huyen}</option>
                    {dsHuyen?.map((item2, index2) => {
                        return <option key={index2}>{item2?.tenHuyen}</option>;
                    })}
                </select>
                <select onChange={(e) => handleChonXa(e.target.value)}>
                    <option> {xa}</option>
                    {dsXa?.map((item3, index3) => {
                        return <option key={index3}>{item3?.tenXa}</option>;
                    })}
                </select>
            </div>
            <input
                className="soNha"
                placeholder={thonXom}
                type="text"
                onChange={(e) => setthonXom(e.target.value)}
            />
        </div>
    );
};
export default ChonDiaChi;
