import "./UpdateMyDetail.scss";
import { useNavigate, useParams } from "react-router-dom";
import CommonUtils from "../component/CommonUtils";
import { resizeAnh } from "../component/UploadAnh";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import anhHaiHuoc from "../assets/images/anhHaiHuoc.jpg";
import {
    updatePost,
    getPost,
    registerPost,
    getTaiKhoan,
    registerTaiKhoan,
} from "../redux/apiRequest";
import ChonDiaChi from "../component/ChonDiaChi";
const UpdateMyDetail = (props) => {
    const { loading, setloading } = props;
    const user = useSelector((state) => state.auth.login?.currentUser);
    const myDetail = useSelector((state) => state.post.post?.myDetail);
    const trangThaiLuu = useSelector((state) => state.post.post?.isFetching);
    const taiKhoan = useSelector(
        (state) => state?.taiKhoan?.taiKhoan?.taiKhoan?.taiKhoan
    );
    const allTaiKhoan2 = useSelector(
        (state) => state?.taiKhoan?.taiKhoan?.allTaiKhoan?.allTaiKhoan
    );
    const [allTaiKhoan, setallTaiKhoan] = useState(allTaiKhoan2);
    useEffect(() => {
        setallTaiKhoan(allTaiKhoan2);
    }, [allTaiKhoan2]);
    const [dateMax, setdateMax] = useState(0);
    const [dateMin, setdateMin] = useState(1);
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    const dispatch = useDispatch();

    useEffect(() => {
        const GDVao = 0;
        const GDRa = "";
        getTaiKhoan(
            user?._id,
            dateMax,
            dateMin,
            1,
            GDVao,
            GDRa,
            0,
            2,
            dispatch
        );
    }, [user]);
    // Thong Tin Chi Tiet
    const [avatar, setavatar] = useState(anhHaiHuoc);
    const [cash, setcash] = useState("100000");
    const [hoTen, sethoTen] = useState("Đào Chưa Chín");
    const [soDienThoai, setsoDienThoai] = useState("0987666888");
    const [gioiTinh, setgioiTinh] = useState("Nam");

    const [ngaySinh, setngaySinh] = useState("9");
    const [thangSinh, setthangSinh] = useState("9");
    const [namSinh, setnamSinh] = useState("1999");

    const [thonXom, setthonXom] = useState("Thôn Lá Đào");
    const [xa, setxa] = useState("Xã Cành Đào");
    const [huyen, sethuyen] = useState("Huyện Thân Đào");
    const [tinh, settinh] = useState("Tỉnh Gốc Đào");

    const [tenNganHang, settenNganHang] = useState("MBBank");
    const [maSoNganHang, setmaSoNganHang] = useState("970422");
    const [taiKhoanNganHang, settaiKhoanNganHang] = useState("0931969456666");
    const [chuTaiKhoanNganhang, setchuTaiKhoanNganhang] =
        useState("Tài Khoản Demo");

    useEffect(() => {
        if (myDetail) {
            setavatar(myDetail?.avatar || anhHaiHuoc);
            setcash(myDetail?.cash || "100000");
            sethoTen(myDetail?.hoTen || "Đào Chưa Chín");
            setsoDienThoai(myDetail?.soDienThoai || "0987666888");
            setgioiTinh(myDetail?.gioiTinh || "Nam");

            setngaySinh(myDetail?.ngaySinh || "9");
            setthangSinh(myDetail?.thangSinh || "9");
            setnamSinh(myDetail?.namSinh || "1999");

            setthonXom(myDetail?.thonXom || "Thôn Lá Đào");
            setxa(myDetail?.xa || "Xã Cành Đào");
            sethuyen(myDetail?.huyen || "Huyện Thân Đào");
            settinh(myDetail?.tinh || "Tỉnh Gốc Đào");

            settenNganHang(
                myDetail?.ttThemUser?.nganHang?.tenNganHang || "MBBank"
            );
            setmaSoNganHang(
                myDetail?.ttThemUser?.nganHang?.maSoNganHang || "970422"
            );
            settaiKhoanNganHang(
                myDetail?.ttThemUser?.nganHang?.taiKhoanNganHang ||
                    "0931969456666"
            );
            setchuTaiKhoanNganhang(
                myDetail?.ttThemUser?.nganHang?.chuTaiKhoanNganhang ||
                    "Tài Khoản Demo"
            );
        }
    }, []);
    const danhSachNganHang = [
        { maSo: "970405", tenNganHang: "Agribank" },
        { maSo: "970422", tenNganHang: "MBBank" },
        { maSo: "970407", tenNganHang: "Teckcombank" },
        { maSo: "970415", tenNganHang: "Vietinbank" },
        { maSo: "970436", tenNganHang: "Vietcombank" },
    ];
    // nam
    var presentDate = new Date();
    const year = presentDate.getFullYear();
    const arrYear = [];
    for (let i = 0; i <= 70; i++) {
        arrYear.push(year - i);
    }

    // thang
    const arrMonth = [];
    for (let i = 1; i <= 12; i++) {
        arrMonth.push(i);
    }

    // ngay
    const arrDate = [];
    for (let i = 1; i <= 31; i++) {
        arrDate.push(i);
    }
    const handleOnchangeImage = async (event) => {
        const anhResize = await resizeAnh(event);
        if (anhResize) {
            setavatar(anhResize);
        }
    };
    const handleLuuThongTinCaNhan = () => {
        try {
            const newPost = {
                avatar: avatar,
                hoTen: hoTen,
                soDienThoai: soDienThoai,
                gioiTinh: gioiTinh,
                // ngay sinh
                ngaySinh: ngaySinh,
                thangSinh: thangSinh,
                namSinh: namSinh,
                // Hien Dang Song
                tinh: tinh,
                huyen: huyen,
                xa: xa,
                thonXom: thonXom,
                cash: cash,
                // vaiTro:vaiTro
                ttThemUser: {
                    nganHang: {
                        tenNganHang: danhSachNganHang?.find(
                            (item) => item?.maSo === maSoNganHang
                        )?.tenNganHang,
                        maSoNganHang: maSoNganHang,
                        taiKhoanNganHang: taiKhoanNganHang,
                        chuTaiKhoanNganhang: chuTaiKhoanNganhang,
                    },
                },
                user: user?._id,
            };
            if (myDetail) {
                const id = myDetail?._id;

                const luutt = updatePost(newPost, id, dispatch);
                if (luutt) {
                    setloading(0);
                }
            } else {
                const luutt2 = registerPost(newPost, dispatch);

                if (luutt2) {
                    setloading(0);
                }
            }
            if (allTaiKhoan && allTaiKhoan?.length === 0) {
                const newTaiKhoan = {
                    GDVao: 99000,
                    GDRa: "",
                    noiDungCK: "Fabysa Tặng",
                    xacNhanChuyenTien: "Thành Công",
                    thongTinThem: {
                        tenChuTk: hoTen,
                        sdtChuTk: user?.username,
                        loaiTK: "User",
                    },
                    idChuTaiKhoan: user?._id,
                };
                console.log("newTaiKhoan", newTaiKhoan);
                setallTaiKhoan([newTaiKhoan]);
                registerTaiKhoan(newTaiKhoan, dispatch);
            }
        } catch (err) {
            console.log(err);
        }
    };
    // Thong Tin Chi Tiet
    //  Viet QR
    // const nganHang = tenNganHang;
    const BANK_ID = maSoNganHang;
    const ACCOUNT_NO = taiKhoanNganHang;
    const TEMPLATE = "print";
    const AMOUNT = "";
    const DESCRIPTION = "";
    const ACCOUNT_NAME = chuTaiKhoanNganhang;
    const qr = `https://img.vietqr.io/image/${BANK_ID}-${ACCOUNT_NO}-${TEMPLATE}.png?amount=${AMOUNT}&addInfo=${DESCRIPTION}&accountName=${ACCOUNT_NAME}`;
    // Viet QR

    return (
        <div className="UpdateMyDetail-ConTaiNer">
            <div className="quayLai-tieuDe">
                <div onClick={() => setloading(0)} className="quayLai">
                    <i className="fa fa-angle-double-left"></i>Quay Lại
                </div>
                <div className="tieuDe">Sửa Thông Tin</div>
            </div>
            <div className="thongTin">
                <div className="hoTen-sdt">
                    <div className="hoTen-input">
                        <div className="hoTen">Họ & Tên</div>
                        <input
                            className="input"
                            type="text"
                            placeholder={hoTen}
                            onChange={(e) => sethoTen(e.target.value)}
                        />
                    </div>
                    <div className="hoTen-input">
                        <div className="hoTen">Số Điện Thoại</div>
                        <input
                            className="input"
                            type="text"
                            placeholder={soDienThoai}
                            onChange={(e) => setsoDienThoai(e.target.value)}
                        />
                    </div>
                    <div className="gioiTinh-select">
                        <div className="gioiTinh">Giới Tính</div>
                        <select
                            className="select"
                            name="gioi-tinh"
                            id="gioi-tinh"
                            onChange={(e) => setgioiTinh(e.target.value)}
                        >
                            <option>{gioiTinh}</option>
                            <option>Nam</option>
                            <option>Nữ</option>
                            <option>Khác</option>
                        </select>
                    </div>
                </div>
                <div className="avatar-taiKhoan">
                    <div className="container-avatar">
                        <input
                            id="avatar"
                            type="file"
                            hidden
                            onChange={handleOnchangeImage}
                            className="avatarFormregis"
                        />
                        <label htmlFor="avatar" className="avatarFormregis">
                            <img src={avatar} className="avatar" />
                        </label>
                    </div>
                    {user?.admin === true ? (
                        <div className="taiKhoan">
                            <input
                                className="input"
                                type="text"
                                placeholder={cash}
                                onChange={(e) => setcash(e.target.value)}
                            />
                        </div>
                    ) : (
                        <div className="taiKhoan">
                            {cash}&#160;
                            <i
                                className="fab fa-empire"
                                style={{ color: "#ef9b0f" }}
                            ></i>
                        </div>
                    )}

                    <div className="quyDoi">
                        1 VNĐ = 1 Fabysa Gold&#160;
                        <i
                            className="fab fa-empire"
                            style={{ color: "#ef9b0f" }}
                        ></i>
                    </div>
                </div>
            </div>

            <div className="ngaySinh-select">
                <div className="ngaySinh">Ngày Sinh</div>
                <div className="select-container">
                    <select
                        className="select"
                        onChange={(e) => setngaySinh(e.target.value)}
                    >
                        <option value="">{ngaySinh}</option>
                        {arrDate &&
                            arrDate.length > 0 &&
                            arrDate.map((item, index) => {
                                return <option key={index}>{item}</option>;
                            })}
                    </select>
                    <select
                        className="select"
                        onChange={(e) => setthangSinh(e.target.value)}
                    >
                        <option value="">{thangSinh}</option>
                        {arrMonth &&
                            arrMonth.length > 0 &&
                            arrMonth.map((item, index) => {
                                return <option key={index}>{item}</option>;
                            })}
                    </select>
                    <select
                        className="select"
                        onChange={(e) => setnamSinh(e.target.value)}
                    >
                        <option value="">{namSinh}</option>
                        {arrYear &&
                            arrYear.length > 0 &&
                            arrYear.map((item, index) => {
                                return <option key={index}>{item}</option>;
                            })}
                    </select>
                </div>
            </div>
            <div className="diaChi">
                <ChonDiaChi
                    thonXom={thonXom}
                    setthonXom={setthonXom}
                    xa={xa}
                    setxa={setxa}
                    huyen={huyen}
                    sethuyen={sethuyen}
                    tinh={tinh}
                    settinh={settinh}
                />
            </div>
            <div className="tkNganHang-container">
                <div className="tieuDe">Tài Khoản Ngân Hàng</div>
                <div className="taikhoan">
                    <div className="ttTaiKhoan">
                        <div className="stk">Ngân hàng</div>
                        <select
                            id="provinces"
                            onChange={(e) => setmaSoNganHang(e.target.value)}
                        >
                            <option>{tenNganHang}</option>
                            {danhSachNganHang?.map((item) => {
                                return (
                                    <option value={item.maSo} key={item.maSo}>
                                        {item.tenNganHang}
                                    </option>
                                );
                            })}
                        </select>
                        <div className="stk">Số tài Khoản</div>
                        <input
                            onChange={(e) =>
                                settaiKhoanNganHang(e.target.value)
                            }
                            type="number"
                            placeholder={taiKhoanNganHang}
                        />
                        <div className="stk">Chủ Tài Khoản</div>
                        <input
                            onChange={(e) =>
                                setchuTaiKhoanNganhang(e.target.value)
                            }
                            className="chuTk"
                            placeholder={chuTaiKhoanNganhang}
                        />
                    </div>
                    <div className="qr-container">
                        <img className="maQr" src={qr} />
                    </div>
                </div>
            </div>
            <div
                onClick={() => handleLuuThongTinCaNhan()}
                className="luuThongTin"
            >
                Lưu Thông Tin
            </div>
        </div>
    );
};
export default UpdateMyDetail;
