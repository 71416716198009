import "./DonViHanhChinhVN.scss";
import React, { useRef, useState } from "react";
import { registerDVHC, getDVHC, updateDVHC } from "../redux/apiRequest";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
const DonViHanhChinhVN = () => {
    const chieuRongMH = window.innerWidth;
    const user = useSelector((state) => state.auth.login?.currentUser);
    const donViHanhChinh = useSelector(
        (state) =>
            state.donViHanhChinh.donViHanhChinh.donViHanhChinh?.donViHanhChinh
    );
    console.log("donViHanhChinh", donViHanhChinh);
    const donViHanhChinhVN2 = donViHanhChinh?.donViHanhChinhVN;
    const _id = donViHanhChinh?._id;
    const dispatch = useDispatch();
    // const donViHanhChinhVN2 = [
    //     {
    //         tenTinh: "Ha Nam",
    //         dsHuyen: [
    //             {
    //                 tenHuyen: "LyNhan",
    //                 dsXa: [
    //                     {
    //                         tenXa: "Xa Hoa Hau",
    //                         dsThonXom: [
    //                             { tenThonXom: "thon 8" },
    //                             { tenThonXom: "thon 9" },
    //                             { tenThonXom: "thon 10" },
    //                         ],
    //                     },
    //                     {
    //                         tenXa: "Xa Tien Thang",
    //                         dsThonXom: [{ tenThonXom: "thon Mui Don Can" }],
    //                     },
    //                 ],
    //             },
    //             {
    //                 tenHuyen: "Duy Tien",
    //                 dsXa: [
    //                     {
    //                         tenXa: "Xa 1",
    //                         dsThonXom: [{ tenThonXom: "thon 2" }],
    //                     },
    //                     {
    //                         tenXa: "Xa 2",
    //                         dsThonXom: [{ tenThonXom: "thon 3" }],
    //                     },
    //                 ],
    //             },
    //         ],
    //     },
    //     {
    //         tenTinh: "Nam Dinh",
    //         dsHuyen: [
    //             {
    //                 tenHuyen: "My Loc",
    //                 dsXa: [
    //                     {
    //                         tenXa: "Cap Tien",
    //                         dsThonXom: [{ tenThonXom: "lang Chai" }],
    //                     },
    //                     {
    //                         tenXa: "Xa Van Khoanh",
    //                         dsThonXom: [{ tenThonXom: "lang buon" }],
    //                     },
    //                 ],
    //             },
    //         ],
    //     },
    // ];
    const [donViHanhChinhVN3, setdonViHanhChinhVN3] =
        useState(donViHanhChinhVN2);
    const [donViHanhChinhVN, setdonViHanhChinhVN] = useState(
        donViHanhChinhVN2?.map((item) => item && { tenTinh: item?.tenTinh })
    );
    useEffect(() => {
        const allDVHC = "allDVHC";
        const tinhHien = "Tỉnh/TP";
        const huyenHien = "Quận/Huyện";
        const xaHien = "Xã/Phường";
        getDVHC(allDVHC, tinhHien, huyenHien, xaHien, dispatch);
    }, []);
    console.log("donViHanhChinhVN", donViHanhChinhVN);
    console.log("donViHanhChinhVN2", donViHanhChinhVN2);
    const [tinhSua, settinhSua] = useState("");
    const [huyenSua, sethuyenSua] = useState("");
    const [xaSua, setxaSua] = useState("");

    const [anHienBoxData, setanHienBoxData] = useState(false);
    const [dataMoi, setdataMoi] = useState("");
    const handleHienBoxData = (tenTinh, tenHuyen, tenXa) => {
        settinhSua(tenTinh);
        sethuyenSua(tenHuyen);
        setxaSua(tenXa);
        setanHienBoxData(true);
    };
    const handleAnBoxData = () => {
        settinhSua();
        sethuyenSua();
        setxaSua();
        setdataMoi("");
        setanHienBoxData(false);
    };
    const handleThemDonViHanhChinh = () => {
        if (dataMoi) {
            if (!tinhSua && !huyenSua && !xaSua) {
                // hienThi
                const xetTinhMoi = donViHanhChinhVN.find(
                    (item) => item.tenTinh === dataMoi
                );
                if (!xetTinhMoi) {
                    setdonViHanhChinhVN([
                        ...donViHanhChinhVN,
                        { tenTinh: dataMoi },
                    ]);
                }
                // capNhat
                const xetTinhMoi2 = donViHanhChinhVN2.find(
                    (item) => item.tenTinh === dataMoi
                );
                if (!xetTinhMoi2) {
                    const donViHanhChinhVNMoi = [
                        ...donViHanhChinhVN2,
                        { tenTinh: dataMoi },
                    ];
                    const donViHanhChinh = {
                        donViHanhChinhVN: donViHanhChinhVNMoi,
                        // _id: _id,
                    };
                    updateDVHC(donViHanhChinh, _id, dispatch);

                    settinhSua();
                    sethuyenSua();
                    setxaSua();
                    setdataMoi("");
                    setanHienBoxData(false);
                    document.getElementById("inputDataMoi").value = "";
                } else {
                    settinhSua();
                    sethuyenSua();
                    setxaSua();
                    setdataMoi("");
                    setanHienBoxData(false);
                    document.getElementById("inputDataMoi").value = "";
                }
            }
            if (tinhSua && !huyenSua && !xaSua) {
                // hienThi
                const dsHuyenCu = donViHanhChinhVN?.find(
                    (item) => item?.tenTinh === tinhSua
                )?.dsHuyen;

                if (dsHuyenCu) {
                    const xetHuyenMoi = dsHuyenCu?.find(
                        (item) => !item?.tenHuyen || item?.tenHuyen === dataMoi
                    );
                    if (!xetHuyenMoi) {
                        const dsHuyenMoi = [
                            ...dsHuyenCu,
                            { tenHuyen: dataMoi },
                        ];
                        setdonViHanhChinhVN(
                            donViHanhChinhVN?.map((item) =>
                                item?.tenTinh !== tinhSua
                                    ? item
                                    : {
                                          tenTinh: tinhSua,
                                          dsHuyen: dsHuyenMoi,
                                      }
                            )
                        );
                    }
                } else {
                    const dsHuyenMoi = [{ tenHuyen: dataMoi }];
                    setdonViHanhChinhVN(
                        donViHanhChinhVN?.map((item) =>
                            item?.tenTinh !== tinhSua
                                ? item
                                : {
                                      tenTinh: tinhSua,
                                      dsHuyen: dsHuyenMoi,
                                  }
                        )
                    );
                }
                // CapNhat
                const dsHuyenCu2 = donViHanhChinhVN2?.find(
                    (item) => item?.tenTinh === tinhSua
                )?.dsHuyen;

                if (dsHuyenCu2) {
                    const xetHuyenMoi2 = dsHuyenCu2?.find(
                        (item) => !item?.tenHuyen || item?.tenHuyen === dataMoi
                    );
                    if (!xetHuyenMoi2) {
                        const dsHuyenMoi2 = [
                            ...dsHuyenCu2,
                            { tenHuyen: dataMoi },
                        ];

                        const donViHanhChinhVNMoi = donViHanhChinhVN2?.map(
                            (item) =>
                                item?.tenTinh !== tinhSua
                                    ? item
                                    : {
                                          tenTinh: tinhSua,
                                          dsHuyen: dsHuyenMoi2,
                                      }
                        );
                        const donViHanhChinh = {
                            donViHanhChinhVN: donViHanhChinhVNMoi,
                            // _id: _id,
                        };
                        updateDVHC(donViHanhChinh, _id, dispatch);

                        settinhSua();
                        sethuyenSua();
                        setxaSua();
                        setdataMoi("");
                        setanHienBoxData(false);
                        document.getElementById("inputDataMoi").value = "";
                    } else {
                        settinhSua();
                        sethuyenSua();
                        setxaSua();
                        setdataMoi("");
                        setanHienBoxData(false);
                        document.getElementById("inputDataMoi").value = "";
                    }
                } else {
                    const dsHuyenMoi2 = [{ tenHuyen: dataMoi }];

                    const donViHanhChinhVNMoi = donViHanhChinhVN2?.map((item) =>
                        item?.tenTinh !== tinhSua
                            ? item
                            : {
                                  tenTinh: tinhSua,
                                  dsHuyen: dsHuyenMoi2,
                              }
                    );
                    const donViHanhChinh = {
                        donViHanhChinhVN: donViHanhChinhVNMoi,
                        // _id: _id,
                    };
                    updateDVHC(donViHanhChinh, _id, dispatch);

                    settinhSua();
                    sethuyenSua();
                    setxaSua();
                    setdataMoi("");
                    setanHienBoxData(false);
                    document.getElementById("inputDataMoi").value = "";
                }
            }
            if (tinhSua && huyenSua && !xaSua) {
                // hienThi
                const dsHuyenCu = donViHanhChinhVN?.find(
                    (item) => item?.tenTinh === tinhSua
                )?.dsHuyen;

                const dsXaCu = dsHuyenCu?.find(
                    (item) => item?.tenHuyen === huyenSua
                )?.dsXa;

                if (dsXaCu) {
                    const xetXaMoi = dsXaCu?.find(
                        (item) => item?.tenXa === dataMoi
                    );
                    if (!xetXaMoi) {
                        const dsXaMoi = [...dsXaCu, { tenXa: dataMoi }];
                        const dsHuyenMoi = dsHuyenCu?.map((item) =>
                            item?.tenHuyen !== huyenSua
                                ? item
                                : {
                                      tenHuyen: huyenSua,
                                      dsXa: dsXaMoi,
                                  }
                        );

                        setdonViHanhChinhVN(
                            donViHanhChinhVN?.map((item) =>
                                item?.tenTinh !== tinhSua
                                    ? item
                                    : {
                                          tenTinh: tinhSua,
                                          dsHuyen: dsHuyenMoi,
                                      }
                            )
                        );
                    }
                } else {
                    const dsXaMoi = [{ tenXa: dataMoi }];
                    const dsHuyenMoi = dsHuyenCu?.map((item) =>
                        item?.tenHuyen !== huyenSua
                            ? item
                            : {
                                  tenHuyen: huyenSua,
                                  dsXa: dsXaMoi,
                              }
                    );

                    setdonViHanhChinhVN(
                        donViHanhChinhVN?.map((item) =>
                            item?.tenTinh !== tinhSua
                                ? item
                                : {
                                      tenTinh: tinhSua,
                                      dsHuyen: dsHuyenMoi,
                                  }
                        )
                    );
                }
                // capNhat
                const dsHuyenCu2 = donViHanhChinhVN2?.find(
                    (item) => item?.tenTinh === tinhSua
                )?.dsHuyen;

                const dsXaCu2 = dsHuyenCu2?.find(
                    (item) => item?.tenHuyen === huyenSua
                )?.dsXa;

                if (dsXaCu2) {
                    const xetXaMoi2 = dsXaCu2?.find(
                        (item) => item?.tenXa === dataMoi
                    );
                    if (!xetXaMoi2) {
                        const dsXaMoi2 = [...dsXaCu2, { tenXa: dataMoi }];
                        const dsHuyenMoi2 = dsHuyenCu2?.map((item) =>
                            item?.tenHuyen !== huyenSua
                                ? item
                                : {
                                      tenHuyen: huyenSua,
                                      dsXa: dsXaMoi2,
                                  }
                        );

                        const donViHanhChinhVNMoi = donViHanhChinhVN2?.map(
                            (item) =>
                                item?.tenTinh !== tinhSua
                                    ? item
                                    : {
                                          tenTinh: tinhSua,
                                          dsHuyen: dsHuyenMoi2,
                                      }
                        );
                        const donViHanhChinh = {
                            donViHanhChinhVN: donViHanhChinhVNMoi,
                            // _id: _id,
                        };
                        updateDVHC(donViHanhChinh, _id, dispatch);

                        settinhSua();
                        sethuyenSua();
                        setxaSua();
                        setdataMoi("");
                        setanHienBoxData(false);
                        document.getElementById("inputDataMoi").value = "";
                    } else {
                        settinhSua();
                        sethuyenSua();
                        setxaSua();
                        setdataMoi("");
                        setanHienBoxData(false);
                        document.getElementById("inputDataMoi").value = "";
                    }
                } else {
                    const dsXaMoi2 = [{ tenXa: dataMoi }];
                    const dsHuyenMoi2 = dsHuyenCu2?.map((item) =>
                        item?.tenHuyen !== huyenSua
                            ? item
                            : {
                                  tenHuyen: huyenSua,
                                  dsXa: dsXaMoi2,
                              }
                    );
                    const donViHanhChinhVNMoi = donViHanhChinhVN2?.map((item) =>
                        item?.tenTinh !== tinhSua
                            ? item
                            : {
                                  tenTinh: tinhSua,
                                  dsHuyen: dsHuyenMoi2,
                              }
                    );
                    const donViHanhChinh = {
                        donViHanhChinhVN: donViHanhChinhVNMoi,
                        // _id: _id,
                    };
                    updateDVHC(donViHanhChinh, _id, dispatch);

                    settinhSua();
                    sethuyenSua();
                    setxaSua();
                    setdataMoi("");
                    setanHienBoxData(false);
                    document.getElementById("inputDataMoi").value = "";
                }
            }
            if (tinhSua && huyenSua && xaSua) {
                // hienThi
                const dsHuyenCu = donViHanhChinhVN?.find(
                    (item) => item?.tenTinh === tinhSua
                )?.dsHuyen;

                const dsXaCu = dsHuyenCu?.find(
                    (item) => item?.tenHuyen === huyenSua
                )?.dsXa;

                const dsThonXomCu = dsXaCu?.find(
                    (item) => item?.tenXa === xaSua
                )?.dsThonXom;

                if (dsThonXomCu) {
                    const xetThonXomMoi = dsThonXomCu?.find(
                        (item) => item?.tenThonXom === dataMoi
                    );
                    if (!xetThonXomMoi) {
                        const dsThonXomMoi = [
                            ...dsThonXomCu,
                            { tenThonXom: dataMoi },
                        ];
                        const dsXaMoi = dsXaCu?.map((item) =>
                            item?.tenXa !== xaSua
                                ? item
                                : {
                                      tenXa: xaSua,
                                      dsThonXom: dsThonXomMoi,
                                  }
                        );
                        const dsHuyenMoi = dsHuyenCu?.map((item) =>
                            item?.tenHuyen !== huyenSua
                                ? item
                                : {
                                      tenHuyen: huyenSua,
                                      dsXa: dsXaMoi,
                                  }
                        );

                        setdonViHanhChinhVN(
                            donViHanhChinhVN?.map((item) =>
                                item?.tenTinh !== tinhSua
                                    ? item
                                    : {
                                          tenTinh: tinhSua,
                                          dsHuyen: dsHuyenMoi,
                                      }
                            )
                        );
                    }
                } else {
                    const dsThonXomMoi = [{ tenThonXom: dataMoi }];
                    const dsXaMoi = dsXaCu?.map((item) =>
                        item?.tenXa !== xaSua
                            ? item
                            : {
                                  tenXa: xaSua,
                                  dsThonXom: dsThonXomMoi,
                              }
                    );
                    const dsHuyenMoi = dsHuyenCu?.map((item) =>
                        item?.tenHuyen !== huyenSua
                            ? item
                            : {
                                  tenHuyen: huyenSua,
                                  dsXa: dsXaMoi,
                              }
                    );
                    setdonViHanhChinhVN(
                        donViHanhChinhVN?.map((item) =>
                            item?.tenTinh !== tinhSua
                                ? item
                                : {
                                      tenTinh: tinhSua,
                                      dsHuyen: dsHuyenMoi,
                                  }
                        )
                    );
                }
                // capNhat
                const dsHuyenCu2 = donViHanhChinhVN2?.find(
                    (item) => item?.tenTinh === tinhSua
                )?.dsHuyen;

                const dsXaCu2 = dsHuyenCu2?.find(
                    (item) => item?.tenHuyen === huyenSua
                )?.dsXa;

                const dsThonXomCu2 = dsXaCu2?.find(
                    (item) => item?.tenXa === xaSua
                )?.dsThonXom;

                if (dsThonXomCu2) {
                    const xetThonXomMoi2 = dsThonXomCu2?.find(
                        (item) => item?.tenThonXom === dataMoi
                    );
                    if (!xetThonXomMoi2) {
                        const dsThonXomMoi2 = [
                            ...dsThonXomCu2,
                            { tenThonXom: dataMoi },
                        ];
                        const dsXaMoi2 = dsXaCu2?.map((item) =>
                            item?.tenXa !== xaSua
                                ? item
                                : {
                                      tenXa: xaSua,
                                      dsThonXom: dsThonXomMoi2,
                                  }
                        );
                        const dsHuyenMoi2 = dsHuyenCu2?.map((item) =>
                            item?.tenHuyen !== huyenSua
                                ? item
                                : {
                                      tenHuyen: huyenSua,
                                      dsXa: dsXaMoi2,
                                  }
                        );

                        const donViHanhChinhVNMoi = donViHanhChinhVN2?.map(
                            (item) =>
                                item?.tenTinh !== tinhSua
                                    ? item
                                    : {
                                          tenTinh: tinhSua,
                                          dsHuyen: dsHuyenMoi2,
                                      }
                        );
                        const donViHanhChinh = {
                            donViHanhChinhVN: donViHanhChinhVNMoi,
                            // _id: _id,
                        };
                        updateDVHC(donViHanhChinh, _id, dispatch);

                        settinhSua();
                        sethuyenSua();
                        setxaSua();
                        setdataMoi("");
                        setanHienBoxData(false);
                        document.getElementById("inputDataMoi").value = "";
                    } else {
                        settinhSua();
                        sethuyenSua();
                        setxaSua();
                        setdataMoi("");
                        setanHienBoxData(false);
                        document.getElementById("inputDataMoi").value = "";
                    }
                } else {
                    const dsThonXomMoi2 = [{ tenThonXom: dataMoi }];
                    const dsXaMoi2 = dsXaCu2?.map((item) =>
                        item?.tenXa !== xaSua
                            ? item
                            : {
                                  tenXa: xaSua,
                                  dsThonXom: dsThonXomMoi2,
                              }
                    );
                    const dsHuyenMoi2 = dsHuyenCu2?.map((item) =>
                        item?.tenHuyen !== huyenSua
                            ? item
                            : {
                                  tenHuyen: huyenSua,
                                  dsXa: dsXaMoi2,
                              }
                    );

                    const donViHanhChinhVNMoi = donViHanhChinhVN2?.map((item) =>
                        item?.tenTinh !== tinhSua
                            ? item
                            : {
                                  tenTinh: tinhSua,
                                  dsHuyen: dsHuyenMoi2,
                              }
                    );
                    const donViHanhChinh = {
                        donViHanhChinhVN: donViHanhChinhVNMoi,
                        // _id: _id,
                    };
                    updateDVHC(donViHanhChinh, _id, dispatch);

                    settinhSua();
                    sethuyenSua();
                    setxaSua();
                    setdataMoi("");
                    setanHienBoxData(false);
                    document.getElementById("inputDataMoi").value = "";
                }
            }
        } else {
            alert("Dữ liệu trống !");
        }
    };
    const handleXoaDonViHanhChinh = (tinhXoa, huyenXoa, xaXoa, thonXomXoa) => {
        const cauHoi = window.confirm("Xoá đơn vị hành chính này?");
        if (cauHoi) {
            if (tinhXoa && !huyenXoa && !xaXoa && !thonXomXoa) {
                // hienThi
                setdonViHanhChinhVN(
                    donViHanhChinhVN?.filter(
                        (item) => item?.tenTinh !== tinhXoa
                    )
                );
                // capNhat
                const donViHanhChinhVNMoi = donViHanhChinhVN2?.filter(
                    (item) => item?.tenTinh !== tinhXoa
                );
                const donViHanhChinh = {
                    donViHanhChinhVN: donViHanhChinhVNMoi,
                    // _id: _id,
                };
                updateDVHC(donViHanhChinh, _id, dispatch);
            }
            if (tinhXoa && huyenXoa && !xaXoa && !thonXomXoa) {
                // hienThi
                const dsHuyenCu = donViHanhChinhVN?.find(
                    (item) => item?.tenTinh === tinhXoa
                )?.dsHuyen;

                const dsHuyenMoi = dsHuyenCu?.filter(
                    (item) => item?.tenHuyen !== huyenXoa
                );

                setdonViHanhChinhVN(
                    donViHanhChinhVN?.map((item) =>
                        item?.tenTinh !== tinhXoa
                            ? item
                            : {
                                  tenTinh: tinhXoa,
                                  dsHuyen: dsHuyenMoi,
                              }
                    )
                );
                // capNhat
                const dsHuyenCu2 = donViHanhChinhVN2?.find(
                    (item) => item?.tenTinh === tinhXoa
                )?.dsHuyen;

                const dsHuyenMoi2 = dsHuyenCu2?.filter(
                    (item) => item?.tenHuyen !== huyenXoa
                );
                const donViHanhChinhVNMoi = donViHanhChinhVN2?.map((item) =>
                    item?.tenTinh !== tinhXoa
                        ? item
                        : {
                              tenTinh: tinhXoa,
                              dsHuyen: dsHuyenMoi2,
                          }
                );
                const donViHanhChinh = {
                    donViHanhChinhVN: donViHanhChinhVNMoi,
                    // _id: _id,
                };
                updateDVHC(donViHanhChinh, _id, dispatch);
            }
            if (tinhXoa && huyenXoa && xaXoa && !thonXomXoa) {
                // hienThi
                const dsHuyenCu = donViHanhChinhVN?.find(
                    (item) => item?.tenTinh === tinhXoa
                )?.dsHuyen;

                const dsXaCu = dsHuyenCu?.find(
                    (item) => item?.tenHuyen === huyenXoa
                )?.dsXa;

                const dsXaMoi = dsXaCu?.filter((item) => item?.tenXa !== xaXoa);
                const dsHuyenMoi = dsHuyenCu?.map((item) =>
                    item?.tenHuyen !== huyenXoa
                        ? item
                        : {
                              tenHuyen: huyenXoa,
                              dsXa: dsXaMoi,
                          }
                );

                setdonViHanhChinhVN(
                    donViHanhChinhVN?.map((item) =>
                        item?.tenTinh !== tinhXoa
                            ? item
                            : {
                                  tenTinh: tinhXoa,
                                  dsHuyen: dsHuyenMoi,
                              }
                    )
                );
                // capNhat
                const dsHuyenCu2 = donViHanhChinhVN2?.find(
                    (item) => item?.tenTinh === tinhXoa
                )?.dsHuyen;

                const dsXaCu2 = dsHuyenCu2?.find(
                    (item) => item?.tenHuyen === huyenXoa
                )?.dsXa;

                const dsXaMoi2 = dsXaCu2?.filter(
                    (item) => item?.tenXa !== xaXoa
                );
                const dsHuyenMoi2 = dsHuyenCu2?.map((item) =>
                    item?.tenHuyen !== huyenXoa
                        ? item
                        : {
                              tenHuyen: huyenXoa,
                              dsXa: dsXaMoi2,
                          }
                );
                const donViHanhChinhVNMoi = donViHanhChinhVN2?.map((item) =>
                    item?.tenTinh !== tinhXoa
                        ? item
                        : {
                              tenTinh: tinhXoa,
                              dsHuyen: dsHuyenMoi2,
                          }
                );
                const donViHanhChinh = {
                    donViHanhChinhVN: donViHanhChinhVNMoi,
                    // _id: _id,
                };
                updateDVHC(donViHanhChinh, _id, dispatch);
            }
            if (tinhXoa && huyenXoa && xaXoa && thonXomXoa) {
                // hienThi
                const dsHuyenCu = donViHanhChinhVN?.find(
                    (item) => item?.tenTinh === tinhXoa
                )?.dsHuyen;

                const dsXaCu = dsHuyenCu?.find(
                    (item) => item?.tenHuyen === huyenXoa
                )?.dsXa;

                const dsthonXomCu = dsXaCu?.find(
                    (item) => item?.tenXa === xaXoa
                )?.dsThonXom;

                const dsthonXomMoi = dsthonXomCu?.filter(
                    (item) => item?.tenThonXom !== thonXomXoa
                );

                const dsXaMoi = dsXaCu?.map((item) =>
                    item?.tenXa !== xaXoa
                        ? item
                        : {
                              tenXa: xaXoa,
                              dsThonXom: dsthonXomMoi,
                          }
                );
                const dsHuyenMoi = dsHuyenCu?.map((item) =>
                    item?.tenHuyen !== huyenXoa
                        ? item
                        : {
                              tenHuyen: huyenXoa,
                              dsXa: dsXaMoi,
                          }
                );
                setdonViHanhChinhVN(
                    donViHanhChinhVN?.map((item) =>
                        item?.tenTinh !== tinhXoa
                            ? item
                            : {
                                  tenTinh: tinhXoa,
                                  dsHuyen: dsHuyenMoi,
                              }
                    )
                );

                // capNhat
                const dsHuyenCu2 = donViHanhChinhVN2?.find(
                    (item) => item?.tenTinh === tinhXoa
                )?.dsHuyen;

                const dsXaCu2 = dsHuyenCu2?.find(
                    (item) => item?.tenHuyen === huyenXoa
                )?.dsXa;

                const dsthonXomCu2 = dsXaCu2?.find(
                    (item) => item?.tenXa === xaXoa
                )?.dsThonXom;

                const dsthonXomMoi2 = dsthonXomCu2?.filter(
                    (item) => item?.tenThonXom !== thonXomXoa
                );

                const dsXaMoi2 = dsXaCu2?.map((item) =>
                    item?.tenXa !== xaXoa
                        ? item
                        : {
                              tenXa: xaXoa,
                              dsThonXom: dsthonXomMoi2,
                          }
                );
                const dsHuyenMoi2 = dsHuyenCu2?.map((item) =>
                    item?.tenHuyen !== huyenXoa
                        ? item
                        : {
                              tenHuyen: huyenXoa,
                              dsXa: dsXaMoi2,
                          }
                );
                const donViHanhChinhVNMoi = donViHanhChinhVN2?.map((item) =>
                    item?.tenTinh !== tinhXoa
                        ? item
                        : {
                              tenTinh: tinhXoa,
                              dsHuyen: dsHuyenMoi2,
                          }
                );
                const donViHanhChinh = {
                    donViHanhChinhVN: donViHanhChinhVNMoi,
                    // _id: _id,
                };
                updateDVHC(donViHanhChinh, _id, dispatch);
            }
        }
    };
    const handleHienDVHC = (allDVHC, tinhHien, huyenHien, xaHien) => {
        console.log("allDVHC", allDVHC);
        console.log("tinhHien", tinhHien);
        console.log("huyenHien", huyenHien);
        console.log("xaHien", xaHien);
        if (allDVHC === "all" && !tinhHien && !huyenHien && !xaHien) {
            const tatCaTinh = donViHanhChinhVN2?.map(
                (item) => item && { tenTinh: item?.tenTinh }
            );
            const tatCaTinhHien = donViHanhChinhVN?.map(
                (item) => item && { tenTinh: item?.tenTinh }
            );
            console.log("tatCaTinh", tatCaTinh);
            console.log("tatCaTinhHien", tatCaTinhHien);
            if (tatCaTinh?.length !== tatCaTinhHien?.length) {
                setdonViHanhChinhVN(tatCaTinh);
            } else {
                for (let i = 0; i < tatCaTinh?.length; i++) {
                    if (tatCaTinh[i] !== tatCaTinhHien[i]) {
                        setdonViHanhChinhVN(tatCaTinh);
                    }
                }
                setdonViHanhChinhVN([]);
            }
        }
        if (allDVHC === "all" && tinhHien && !huyenHien && !xaHien) {
            const tatCaHuyen = donViHanhChinhVN2
                ?.find((item) => item?.tenTinh === tinhHien)
                ?.dsHuyen?.map((item) => item && { tenHuyen: item?.tenHuyen });
            console.log("tatCaHuyen", tatCaHuyen);
            const tatCaHuyenHien = donViHanhChinhVN
                ?.find((item) => item?.tenTinh === tinhHien)
                ?.dsHuyen?.map((item) => item && { tenHuyen: item?.tenHuyen });
            console.log("tatCaHuyenHien", tatCaHuyenHien);
            if (tatCaHuyen?.length !== tatCaHuyenHien?.length) {
                setdonViHanhChinhVN(
                    donViHanhChinhVN?.map((item) =>
                        item?.tenTinh !== tinhHien
                            ? item
                            : {
                                  tenTinh: tinhHien,
                                  dsHuyen: tatCaHuyen,
                              }
                    )
                );
            } else {
                for (let i = 0; i < tatCaHuyen?.length; i++) {
                    if (tatCaHuyen[i] !== tatCaHuyenHien[i]) {
                        setdonViHanhChinhVN(
                            donViHanhChinhVN?.map((item) =>
                                item?.tenTinh !== tinhHien
                                    ? item
                                    : {
                                          tenTinh: tinhHien,
                                          dsHuyen: tatCaHuyen,
                                      }
                            )
                        );
                    }
                }
                setdonViHanhChinhVN(
                    donViHanhChinhVN?.map((item) =>
                        item?.tenTinh !== tinhHien
                            ? item
                            : {
                                  tenTinh: tinhHien,
                              }
                    )
                );
            }
        }
        if (allDVHC === "all" && tinhHien && huyenHien && !xaHien) {
            const tatCaHuyen = donViHanhChinhVN2
                ?.find((item) => item?.tenTinh === tinhHien)
                ?.dsHuyen?.map((item) => item);
            console.log("tatCaHuyen", tatCaHuyen);
            const tatCaHuyenHien = donViHanhChinhVN
                ?.find((item) => item?.tenTinh === tinhHien)
                ?.dsHuyen?.map((item) => item);
            console.log("tatCaHuyenHien", tatCaHuyenHien);

            const tatCaXa = tatCaHuyen
                ?.find((item) => item?.tenHuyen === huyenHien)
                ?.dsXa?.map((item) => item && { tenXa: item?.tenXa });
            console.log("tatCaXa", tatCaXa);
            const tatCaXaHien = tatCaHuyenHien
                ?.find((item) => item?.tenHuyen === huyenHien)
                ?.dsXa?.map((item) => item && { tenXa: item?.tenXa });
            console.log("tatCaXaHien", tatCaXaHien);

            if (tatCaXa?.length !== tatCaXaHien?.length) {
                setdonViHanhChinhVN(
                    donViHanhChinhVN?.map((item) =>
                        item?.tenTinh !== tinhHien
                            ? item
                            : {
                                  tenTinh: tinhHien,
                                  dsHuyen: tatCaHuyen?.map((item) =>
                                      item?.tenHuyen !== huyenHien
                                          ? item
                                          : {
                                                tenHuyen: huyenHien,
                                                dsXa: tatCaXa,
                                            }
                                  ),
                              }
                    )
                );
            } else {
                for (let i = 0; i < tatCaXa?.length; i++) {
                    if (tatCaXa[i] !== tatCaXaHien[i]) {
                        setdonViHanhChinhVN(
                            donViHanhChinhVN?.map((item) =>
                                item?.tenTinh !== tinhHien
                                    ? item
                                    : {
                                          tenTinh: tinhHien,
                                          dsHuyen: tatCaHuyen?.map((item) =>
                                              item?.tenHuyen !== huyenHien
                                                  ? item
                                                  : {
                                                        tenHuyen: huyenHien,
                                                        dsXa: tatCaXa,
                                                    }
                                          ),
                                      }
                            )
                        );
                    }
                }
                setdonViHanhChinhVN(
                    donViHanhChinhVN?.map((item) =>
                        item?.tenTinh !== tinhHien
                            ? item
                            : {
                                  tenTinh: tinhHien,
                                  dsHuyen: tatCaHuyen?.map((item) =>
                                      item?.tenHuyen !== huyenHien
                                          ? item
                                          : {
                                                tenHuyen: huyenHien,
                                            }
                                  ),
                              }
                    )
                );
            }
        }
        if (allDVHC === "all" && tinhHien && huyenHien && xaHien) {
            const tatCaHuyen = donViHanhChinhVN2
                ?.find((item) => item?.tenTinh === tinhHien)
                ?.dsHuyen?.map((item) => item);
            console.log("tatCaHuyen", tatCaHuyen);
            const tatCaHuyenHien = donViHanhChinhVN
                ?.find((item) => item?.tenTinh === tinhHien)
                ?.dsHuyen?.map((item) => item);
            console.log("tatCaHuyenHien", tatCaHuyenHien);

            const tatCaXa = tatCaHuyen
                ?.find((item) => item?.tenHuyen === huyenHien)
                ?.dsXa?.map((item) => item);
            console.log("tatCaXa", tatCaXa);
            const tatCaXaHien = tatCaHuyenHien
                ?.find((item) => item?.tenHuyen === huyenHien)
                ?.dsXa?.map((item) => item);
            console.log("tatCaXaHien", tatCaXaHien);

            const tatCaThonXom = tatCaXa
                ?.find((item) => item?.tenXa === xaHien)
                ?.dsThonXom?.map(
                    (item) => item && { tenThonXom: item?.tenThonXom }
                );
            console.log("tatCaThonXom", tatCaThonXom);
            const tatCaThonXomHien = tatCaXaHien
                ?.find((item) => item?.tenXa === xaHien)
                ?.dsThonXom?.map(
                    (item) => item && { tenThonXom: item?.tenThonXom }
                );
            console.log("tatCaThonXomHien", tatCaThonXomHien);

            if (tatCaThonXom?.length !== tatCaThonXomHien?.length) {
                setdonViHanhChinhVN(
                    donViHanhChinhVN?.map((item) =>
                        item?.tenTinh !== tinhHien
                            ? item
                            : {
                                  tenTinh: tinhHien,
                                  dsHuyen: tatCaHuyenHien?.map((item) =>
                                      item?.tenHuyen !== huyenHien
                                          ? item
                                          : {
                                                tenHuyen: huyenHien,
                                                dsXa: tatCaXaHien?.map((item) =>
                                                    item?.tenXa !== xaHien
                                                        ? item
                                                        : {
                                                              tenXa: xaHien,
                                                              dsThonXom:
                                                                  tatCaThonXom,
                                                          }
                                                ),
                                            }
                                  ),
                              }
                    )
                );
            } else {
                for (let i = 0; i < tatCaThonXom?.length; i++) {
                    if (tatCaThonXom[i] !== tatCaThonXomHien[i]) {
                        setdonViHanhChinhVN(
                            donViHanhChinhVN?.map((item) =>
                                item?.tenTinh !== tinhHien
                                    ? item
                                    : {
                                          tenTinh: tinhHien,
                                          dsHuyen: tatCaHuyenHien?.map((item) =>
                                              item?.tenHuyen !== huyenHien
                                                  ? item
                                                  : {
                                                        tenHuyen: huyenHien,
                                                        dsXa: tatCaXaHien?.map(
                                                            (item) =>
                                                                item?.tenXa !==
                                                                xaHien
                                                                    ? item
                                                                    : {
                                                                          tenXa: xaHien,
                                                                          dsThonXom:
                                                                              tatCaThonXom,
                                                                      }
                                                        ),
                                                    }
                                          ),
                                      }
                            )
                        );
                    }
                }
                setdonViHanhChinhVN(
                    donViHanhChinhVN?.map((item) =>
                        item?.tenTinh !== tinhHien
                            ? item
                            : {
                                  tenTinh: tinhHien,
                                  dsHuyen: tatCaHuyenHien?.map((item) =>
                                      item?.tenHuyen !== huyenHien
                                          ? item
                                          : {
                                                tenHuyen: huyenHien,
                                                dsXa: tatCaXaHien?.map((item) =>
                                                    item?.tenXa !== xaHien
                                                        ? item
                                                        : {
                                                              tenXa: xaHien,
                                                          }
                                                ),
                                            }
                                  ),
                              }
                    )
                );
            }
        }
    };
    const handleThemDVHC = () => {
        const cauHoi = window.confirm("Tạo mới đơn vị hành chính!");
        if (cauHoi) {
            const donViHanhChinh = {
                donViHanhChinhVN: [{ tenTinh: "Ha Nam" }],
            };
            console.log("donViHanhChinhVNMoi", donViHanhChinh);
            registerDVHC(donViHanhChinh, dispatch);
        }
    };
    return (
        <div className="view">
            <div className="DonViHanhChinhVN-ConTaiNer">
                <div className="quayLai-tieuDe">
                    <a href={`/ca-nhan/a/a/a/a/a/a`} className="quayLai">
                        <i className="fa fa-angle-double-left"></i>
                        Quay Lại
                    </a>
                    <div className="tieuDe">Đơn Vị Hành Chính</div>
                </div>
                <div className="donViHanhChinhVN">
                    <div className="DVHCVN-themTinh">
                        <div className="DVHCVN-taoMoi">
                            <div
                                onClick={() =>
                                    handleHienDVHC("all", "", "", "")
                                }
                                className="DVHCVN"
                            >
                                Đơn Vị Hành Chính Việt Nam
                            </div>
                            {user && user?.admin === true && (
                                <div
                                    onClick={() => handleThemDVHC()}
                                    className="taoMoi"
                                >
                                    <i className="fa fa-plus"></i> Thêm DVHC
                                </div>
                            )}
                        </div>
                        <div
                            onClick={() => handleHienBoxData("", "", "")}
                            className="themTinh"
                        >
                            <i className="fa fa-plus"></i> Thêm Tỉnh/TP
                        </div>
                    </div>

                    {donViHanhChinhVN?.map((item, index) => {
                        return (
                            <div className="dsTinh" key={index}>
                                <div className="tenTinh-xoaTinh">
                                    <div
                                        onClick={() =>
                                            handleHienDVHC(
                                                "all",
                                                item?.tenTinh,
                                                "",
                                                ""
                                            )
                                        }
                                        className="tenTinh"
                                    >
                                        {index + 1}.&#160;
                                        {item?.tenTinh}
                                    </div>

                                    <div
                                        onClick={() =>
                                            handleXoaDonViHanhChinh(
                                                item?.tenTinh,
                                                "",
                                                "",
                                                ""
                                            )
                                        }
                                        className="xoaTinh"
                                    >
                                        <i className="fa fa-remove"></i>
                                    </div>
                                </div>
                                <div
                                    onClick={() =>
                                        handleHienBoxData(item?.tenTinh, "", "")
                                    }
                                    className="themHuyen"
                                >
                                    <i className="fa fa-plus"></i> Thêm
                                    Quận/Huyện
                                </div>
                                {item?.dsHuyen?.map((item2, index2) => {
                                    return (
                                        <div className="dsHuyen" key={index2}>
                                            <div className="tenHuyen-xoaHuyen">
                                                <div
                                                    onClick={() =>
                                                        handleHienDVHC(
                                                            "all",
                                                            item?.tenTinh,
                                                            item2?.tenHuyen,
                                                            ""
                                                        )
                                                    }
                                                    className="tenHuyen"
                                                >
                                                    {index2 + 1}.&#160;
                                                    {item2?.tenHuyen}
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        handleXoaDonViHanhChinh(
                                                            item?.tenTinh,
                                                            item2?.tenHuyen,
                                                            "",
                                                            ""
                                                        )
                                                    }
                                                    className="xoaHuyen"
                                                >
                                                    <i className="fa fa-remove"></i>
                                                </div>
                                            </div>
                                            <div
                                                onClick={() =>
                                                    handleHienBoxData(
                                                        item?.tenTinh,
                                                        item2?.tenHuyen,
                                                        ""
                                                    )
                                                }
                                                className="themXa"
                                            >
                                                <i className="fa fa-plus"></i>{" "}
                                                Thêm Xã/Phường/Thị Trấn
                                            </div>
                                            {item2?.dsXa?.map(
                                                (item3, index3) => {
                                                    return (
                                                        <div
                                                            className="dsXa"
                                                            key={index3}
                                                        >
                                                            <div className="tenXa-xoaXa">
                                                                <div
                                                                    onClick={() =>
                                                                        handleHienDVHC(
                                                                            "all",
                                                                            item?.tenTinh,
                                                                            item2?.tenHuyen,
                                                                            item3?.tenXa
                                                                        )
                                                                    }
                                                                    className="tenXa"
                                                                >
                                                                    {index3 + 1}
                                                                    .&#160;
                                                                    {
                                                                        item3?.tenXa
                                                                    }
                                                                </div>
                                                                <div
                                                                    onClick={() =>
                                                                        handleXoaDonViHanhChinh(
                                                                            item?.tenTinh,
                                                                            item2?.tenHuyen,
                                                                            item3?.tenXa,
                                                                            ""
                                                                        )
                                                                    }
                                                                    className="xoaXa"
                                                                >
                                                                    <i className="fa fa-remove"></i>
                                                                </div>
                                                            </div>
                                                            <div
                                                                onClick={() =>
                                                                    handleHienBoxData(
                                                                        item?.tenTinh,
                                                                        item2?.tenHuyen,
                                                                        item3?.tenXa
                                                                    )
                                                                }
                                                                className="themThonXom"
                                                            >
                                                                <i className="fa fa-plus"></i>{" "}
                                                                Thêm Thôn/Xóm
                                                            </div>
                                                            {item3?.dsThonXom?.map(
                                                                (
                                                                    item4,
                                                                    index4
                                                                ) => {
                                                                    return (
                                                                        <div
                                                                            className="dsThonXom"
                                                                            key={
                                                                                index4
                                                                            }
                                                                        >
                                                                            <div className="tenThonXom-xoaThonXom">
                                                                                <div className="tenThonXom">
                                                                                    {index4 +
                                                                                        1}
                                                                                    .&#160;
                                                                                    {
                                                                                        item4?.tenThonXom
                                                                                    }
                                                                                </div>
                                                                                <div
                                                                                    onClick={() =>
                                                                                        handleXoaDonViHanhChinh(
                                                                                            item?.tenTinh,
                                                                                            item2?.tenHuyen,
                                                                                            item3?.tenXa,
                                                                                            item4?.tenThonXom
                                                                                        )
                                                                                    }
                                                                                    className="xoaThonXom"
                                                                                >
                                                                                    <i className="fa fa-remove"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
                {anHienBoxData === true && (
                    <div className="boxThemDuLieu">
                        <div className="themTinh-container">
                            <div className="themTinh-close">
                                {xaSua ? (
                                    <div className="themTinh">
                                        Thêm Thôn/Xóm/Số Nhà
                                    </div>
                                ) : (
                                    <div>
                                        {huyenSua ? (
                                            <div className="themTinh">
                                                Thêm Xã/Phường/Thị Trấn
                                            </div>
                                        ) : (
                                            <div>
                                                {tinhSua ? (
                                                    <div className="themTinh">
                                                        Thêm Quận/Huyện
                                                    </div>
                                                ) : (
                                                    <div className="themTinh">
                                                        Thêm Tỉnh/TP
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div
                                    onClick={() => handleAnBoxData()}
                                    className="close"
                                >
                                    <i className="fa fa-remove"></i>
                                </div>
                            </div>
                            <div className="input-ok">
                                <input
                                    onChange={(e) => setdataMoi(e.target.value)}
                                    className="input"
                                    id="inputDataMoi"
                                    placeholder="Thêm dữ liệu . . ."
                                />
                                <div
                                    onClick={() => handleThemDonViHanhChinh()}
                                    className="ok"
                                >
                                    <i className="fa fa-check"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default DonViHanhChinhVN;
