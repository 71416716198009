import "./ChonDiaChi.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { registerDVHC, getDVHC, updateDVHC } from "../redux/apiRequest";
const ChonDiaChi = (props) => {
    const { xa2, setxa2, sethuyen2, huyen2, settinh2, tinh2 } =
        props;
    const dispatch = useDispatch();
    const donViHanhChinh = useSelector(
        (state) =>
            state.donViHanhChinh.donViHanhChinh.donViHanhChinh?.donViHanhChinh
    );
    useEffect(() => {
        const allDVHC = "allTinh";
        if (allDVHC && tinh2 && huyen2 && xa2) {
            getDVHC(allDVHC, tinh2, huyen2, xa2, dispatch);
        }
    }, [tinh2, huyen2, xa2]);
    const dsTinh = donViHanhChinh?.donViHanhChinhVN;
    const dsHuyen = dsTinh?.find((item) => item?.tenTinh === tinh2)?.dsHuyen;
    const dsXa = dsHuyen?.find((item) => item?.tenHuyen === huyen2)?.dsXa;
    const handleChonTinh = (t) => {
        settinh2(t);
        sethuyen2("Quận/Huyện");
        setxa2("Xã/Phường");
    };
    const handleChonHuyen = (h) => {
        sethuyen2(h);
        setxa2("Xã/Phường");
    };
    const handleChonXa = (x) => {
        setxa2(x);
    };
    return (
        <div className="ChonDiaChi-ConTaiNer">
            <div className="tinh-huyen-xa">
                <select onChange={(e) => handleChonTinh(e.target.value)}>
                    <option>{tinh2}</option>
                    {dsTinh?.map((item1, index1) => {
                        return <option key={index1}>{item1?.tenTinh}</option>;
                    })}
                </select>
                <select onChange={(e) => handleChonHuyen(e.target.value)}>
                    <option>{huyen2}</option>
                    {dsHuyen?.map((item2, index2) => {
                        return <option key={index2}>{item2?.tenHuyen}</option>;
                    })}
                </select>
                <select onChange={(e) => handleChonXa(e.target.value)}>
                    <option> {xa2}</option>
                    {dsXa?.map((item3, index3) => {
                        return <option key={index3}>{item3?.tenXa}</option>;
                    })}
                </select>
            </div>
        </div>
    );
};
export default ChonDiaChi;
