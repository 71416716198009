import { createSlice } from "@reduxjs/toolkit";
const donViHanhChinhSlice = createSlice({
    name: "donViHanhChinh",
    initialState: {
        donViHanhChinh: {
            donViHanhChinh: null,
            isFetching: false,
            error: false,
            success: false,
        },
    },
    reducers: {
        logOutSuccessDonViHanhChinh: (state) => {
            state.donViHanhChinh.donViHanhChinh = null;
            state.donViHanhChinh.isFetching = false;
            state.donViHanhChinh.error = false;
            state.donViHanhChinh.success = false;
        },
        registerDonViHanhChinhStart: (state) => {
            state.donViHanhChinh.isFetching = true;
        },
        registerDonViHanhChinhSuccess: (state, action) => {
            state.donViHanhChinh.isFetching = false;
            state.donViHanhChinh.error = false;
            state.donViHanhChinh.donViHanhChinh = action.payload;
            state.donViHanhChinh.success = true;
        },
        registerDonViHanhChinhFailed: (state) => {
            state.donViHanhChinh.isFetching = false;
            state.donViHanhChinh.error = true;
            state.donViHanhChinh.success = false;
        },
        getDonViHanhChinhStart: (state) => {
            state.donViHanhChinh.isFetching = true;
        },
        getDonViHanhChinhSuccess: (state, action) => {
            state.donViHanhChinh.isFetching = false;
            state.donViHanhChinh.donViHanhChinh = action.payload;
            state.donViHanhChinh.success = true;
        },
        getDonViHanhChinhFailed: (state) => {
            state.donViHanhChinh.isFetching = false;
            state.donViHanhChinh.error = true;
        },
        updateDonViHanhChinhStart: (state) => {
            state.donViHanhChinh.isFetching = true;
        },
        updateDonViHanhChinhSuccess: (state, action) => {
            state.donViHanhChinh.isFetching = false;
            state.donViHanhChinh.donViHanhChinh = action.payload;
            state.donViHanhChinh.success = true;
        },
        updateDonViHanhChinhFailed: (state) => {
            state.donViHanhChinh.isFetching = false;
            state.donViHanhChinh.error = true;
        },
    },
});

export const {
    registerDonViHanhChinhStart,
    registerDonViHanhChinhSuccess,
    registerDonViHanhChinhFailed,

    getDonViHanhChinhStart,
    getDonViHanhChinhSuccess,
    getDonViHanhChinhFailed,

    updateDonViHanhChinhStart,
    updateDonViHanhChinhSuccess,
    updateDonViHanhChinhFailed,

    logOutSuccessDonViHanhChinh,
} = donViHanhChinhSlice.actions;

export default donViHanhChinhSlice.reducer;
