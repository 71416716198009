import "./UpdateShop.scss";
import CommonUtils from "../component/CommonUtils";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../GiaoDienChung/Loading";
import { resizeAnh } from "../component/UploadAnh";
import BoxSoanThao from "../component/BoxSoanThao";
import {
    getTaiKhoan,
    registerTaiKhoan,
    getttShop,
    updatettShop,
    getPost,
} from "../redux/apiRequest";
import { useEffect } from "react";
import ChonDiaChi from "../component/ChonDiaChi";
const UpdateShop = () => {
    const { idShop } = useParams();
    const chieuRongMH = window.innerWidth;
    const user = useSelector((state) => state.auth.login?.currentUser);
    const myDetail = useSelector((state) => state.post.post?.myDetail);
    const ttShop = useSelector((state) => state.ttShop.ttShop.ttShop?.shop);
    const TTLoading = useSelector((state) => state.ttShop.ttShop.isFetching);
    const allTaiKhoan2 = useSelector(
        (state) => state?.taiKhoan?.taiKhoan?.allTaiKhoan?.allTaiKhoan
    );
    const [dateMax, setdateMax] = useState(0);
    const [dateMin, setdateMin] = useState(1);
    const [allTaiKhoan, setallTaiKhoan] = useState(allTaiKhoan2);
    useEffect(() => {
        setallTaiKhoan(allTaiKhoan2);
    }, [allTaiKhoan2]);
    const dispatch = useDispatch();
    useEffect(() => {
        getPost(user?._id, dispatch);
    }, [user]);
    // shop
    useEffect(() => {
        getttShop(idShop, dispatch);
    }, [idShop]);

    useEffect(() => {
        const GDVao = 0;
        const GDRa = "";
        getTaiKhoan(idShop, dateMax, dateMin, 1, GDVao, GDRa, 0, 2, dispatch);
    }, [idShop]);

    const [TenShop, setTenShop] = useState();
    const [SdtShop, setSdtShop] = useState();
    const [capBac, setcapBac] = useState();
    const [xuatBan, setxuatBan] = useState();

    const [cash, setcash] = useState();
    const [UserShop, setUserShop] = useState();

    // ThongTinThem
    const [Banner, setBanner] = useState();
    const [allAnhShop, setallAnhShop] = useState([]);
    const [giaoDien, setgiaoDien] = useState();
    const [tenVietTat, settenVietTat] = useState();
    const [nguoiHoTro, setnguoiHoTro] = useState();
    const [website, setwebsite] = useState();
    const [gmail, setgmail] = useState();
    const [faceBook, setfaceBook] = useState();

    const [zalo, setzalo] = useState();
    const [slogan, setslogan] = useState();
    const [gioiThieu, setgioiThieu] = useState();
    const [vanBan, setvanBan] = useState();

    // const [nganHang, setnganHang] = useState({});
    const [tenNganHang, settenNganHang] = useState("");
    const [taiKhoanNganHang, settaiKhoanNganHang] = useState("");
    const [chuTaiKhoanNganhang, setchuTaiKhoanNganhang] = useState("");

    const [nvBanHang, setnvBanHang] = useState([]);
    const [nvQuanLy, setnvQuanLy] = useState([]);
    const [menuShop, setmenuShop] = useState([]);
    const [khachSi, setkhachSi] = useState([]);
    const [khachCtv, setkhachCtv] = useState([]);
    const [khachLe, setkhachLe] = useState([]);
    const [soBan, setsoBan] = useState([]);

    const [soBan2, setsoBan2] = useState("");
    const [kinhDo, setkinhDo] = useState(0);
    const [viDo, setviDo] = useState(0);
    useEffect(() => {
        if (ttShop) {
            setallAnhShop(ttShop?.allAnhShop || []);
            setTenShop(ttShop?.TenShop);
            setSdtShop(ttShop?.sdtShop);

            setkinhDo(ttShop?.kinhDo || 0);
            setviDo(ttShop?.viDo || 0);
            // Tinh
            // huyen
            // xa
            setthonXom(ttShop?.thonXom);
            setcash(ttShop?.cash);
            setcapBac(ttShop?.capBac || 1);
            setxuatBan(ttShop?.xuatBan || "Xây Dựng");
            setUserShop(ttShop?.user);
            // ttShopThem
            setBanner(ttShop?.allAnhShop[0] || "");
            setgiaoDien(ttShop?.ttShopThem?.giaoDien || 1);
            settenVietTat(ttShop?.ttShopThem?.tenVietTat || "Tên viết tắt");
            setnguoiHoTro(
                ttShop?.ttShopThem?.nguoiHoTro || "Thêm người hỗ trợ"
            );
            setwebsite(ttShop?.ttShopThem?.website || "Website");
            setgmail(ttShop?.ttShopThem?.gmail || "Địa chỉ Gmail");
            setfaceBook(ttShop?.ttShopThem?.faceBook || "Link Facebook");
            setzalo(ttShop?.ttShopThem?.zalo || "Số điện thoại Zalo");
            setslogan(ttShop?.ttShopThem?.slogan || "Thêm slogan");
            setgioiThieu(ttShop?.ttShopThem?.gioiThieu || "Thêm giới thiệu");
            setvanBan(ttShop?.ttShopThem?.gioiThieu || "Thêm giới thiệu");

            settenNganHang(
                ttShop?.ttShopThem?.nganHang?.tenNganHang ||
                    "---Chọn Ngân Hàng---"
            );
            settaiKhoanNganHang(
                ttShop?.ttShopThem?.nganHang?.taiKhoanNganHang || "Số Tài Khoản"
            );
            setchuTaiKhoanNganhang(
                ttShop?.ttShopThem?.nganHang?.chuTaiKhoanNganhang ||
                    "Chủ Tài Khoản"
            );

            setnvBanHang(ttShop?.ttShopThem?.nvBanHang || []);
            setnvQuanLy(ttShop?.ttShopThem?.nvQuanLy || []);
            setmenuShop(ttShop?.ttShopThem?.menuShop || []);
            setkhachCtv(ttShop?.ttShopThem?.khachCtv || []);
            setkhachLe(ttShop?.ttShopThem?.khachLe || []);
            setkhachSi(ttShop?.ttShopThem?.khachSi || []);
            setsoBan(ttShop?.ttShopThem?.soBan || []);
        }
    }, [ttShop]);
    const danhSachNganHang = [
        { maSo: "970405", tenNganHang: "Agribank" },
        { maSo: "970422", tenNganHang: "MBBank" },
        { maSo: "970407", tenNganHang: "Teckcombank" },
        { maSo: "970415", tenNganHang: "Vietinbank" },
        { maSo: "970436", tenNganHang: "Vietcombank" },
    ];
    // Dia Chi Shop
    const [thonXom, setthonXom] = useState("Thôn/Xóm/Số nhà...");
    const [xa, setxa] = useState("Xã/Phường");
    const [huyen, sethuyen] = useState("Quận/Huyện");
    const [tinh, settinh] = useState("Tỉnh/TP");
    const d = new Date();
    console.log("banner 1", allAnhShop);
    // banner
    const handleThemAnh = async (event) => {
        if (allAnhShop?.length === 0) {
            const file = event.target.files[0];
            let avatarBase64 = await CommonUtils.getBase64(file);
            setallAnhShop([avatarBase64]);
            setBanner(avatarBase64);
        } else {
            const anhResize = await resizeAnh(event);
            if (anhResize) {
                setBanner(anhResize);
                setallAnhShop([...allAnhShop, anhResize]);
            }
        }
    };
    const handleXoaAnh = async (Banner) => {
        const xoaAnh = window.confirm("Xoá ảnh này?");
        if (xoaAnh) {
            setallAnhShop(allAnhShop.filter((item) => item !== Banner));
            setBanner();
        }
    };
    // banner
    const handleLuuThongTinShop = () => {
        // nganHang
        const xetTkNH = danhSachNganHang.find(
            (item) => item.tenNganHang === tenNganHang
        );
        const nganHang = {
            tenNganHang: tenNganHang,
            maSo: xetTkNH?.maSo,
            taiKhoanNganHang: taiKhoanNganHang,
            chuTaiKhoanNganhang: chuTaiKhoanNganhang,
        };

        // nganHang
        const ttShopThem = {
            // Banner,
            giaoDien,
            tenVietTat,
            nguoiHoTro,
            website,
            nganHang,
            nvBanHang,
            nvQuanLy,
            menuShop,
            khachSi,
            khachCtv,
            khachLe,
            gmail,
            faceBook,
            zalo,
            slogan,
            gioiThieu: vanBan,
            soBan,
        };

        try {
            const id = ttShop._id;
            const newShop = {
                TenShop: TenShop,
                sdtShop: SdtShop,

                tinh: tinh || ttShop?.tinh,
                huyen: huyen || ttShop?.huyen,
                xa: xa || ttShop?.xa,
                thonXom: thonXom,

                cash: cash,
                phiNenTang: ttShop?.phiNenTang,
                capBac: capBac,
                xuatBan: xuatBan,
                ttShopThem: ttShopThem,
                allAnhShop: allAnhShop,
                user: UserShop,
                kinhDo: kinhDo,
                viDo: viDo,
            };
            console.log("newShop", newShop);
            updatettShop(newShop, id, dispatch);
            if (allTaiKhoan && allTaiKhoan?.length === 0) {
                const newTaiKhoan = {
                    GDVao: 300000,
                    GDRa: "",
                    noiDungCK: "Fabysa Tặng",
                    xacNhanChuyenTien: "Thành Công",
                    thongTinThem: {
                        tenChuTk: ttShop?.TenShop,
                        sdtChuTk: ttShop?.sdtShop,
                        loaiTK: "Shop",
                    },
                    idChuTaiKhoan: ttShop?._id,
                };
                console.log("newTaiKhoan", newTaiKhoan);
                setallTaiKhoan([newTaiKhoan]);
                registerTaiKhoan(newTaiKhoan, dispatch);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    const handleThemSoBan = () => {
        setsoBan([...soBan, soBan2]);
        document.getElementById("input3").value = "";
        setsoBan2("");
    };
    const handleXoaSoBan = (item) => {
        setsoBan(soBan.filter((item2) => item2 !== item));
    };
    const handleViTriHienTai = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setkinhDo(position.coords.latitude);
                setviDo(position.coords.longitude);
            });
        }
    };
    const handleTenShop = (str, ten) => {
        str = str?.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str?.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str?.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str?.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str?.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str?.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str?.replace(/đ/g, "d");
        str = str?.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str?.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str?.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str?.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str?.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str?.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str?.replace(/Đ/g, "D");
        str = str?.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
        str = str?.replace(/\u02C6|\u0306|\u031B/g, "");
        str = str?.replace(/ + /g, " ");
        str = str?.replace(/  /g, "");
        str = str?.trim();
        str = str?.replace(
            /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
            " "
        );
        str = str.split(" ");
        str = str.join("-");
        settenVietTat(str);
        setTenShop(ten);
    };
    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="UpdateShop-ConTaiNer">
                        <div className="quayLai-tieuDe">
                            <a
                                href={`/quan-ly-shop/${idShop}`}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>Quay
                                Lại
                            </a>
                            <div className="tieuDe">Thông Tin Shop</div>
                        </div>
                        <div className="updateShop">
                            <div className="allAnhShop-container">
                                <div className="banner-container">
                                    {Banner ? (
                                        <div className="anhPreview-xoaAnh">
                                            <img
                                                src={Banner}
                                                className="anhPreview"
                                            />
                                            <div
                                                onClick={() =>
                                                    handleXoaAnh(Banner)
                                                }
                                                className="xoaAnh"
                                            >
                                                <i className="fa fa-remove"></i>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="themAnh">
                                            <i className="fa fa-image"></i>
                                        </div>
                                    )}
                                </div>
                                <div className="allAnh-themAnhShop">
                                    <div className="allAnh">
                                        {allAnhShop &&
                                            allAnhShop?.length !== 0 &&
                                            allAnhShop?.map((item, index) => {
                                                return (
                                                    <img
                                                        key={index}
                                                        src={item}
                                                        className={
                                                            item === Banner
                                                                ? "anh1"
                                                                : "anh2"
                                                        }
                                                        onClick={() =>
                                                            setBanner(item)
                                                        }
                                                    />
                                                );
                                            })}
                                    </div>
                                    {allAnhShop && allAnhShop?.length < 5 && (
                                        <div className="themAnhShop">
                                            <input
                                                id="banner"
                                                type="file"
                                                hidden
                                                onChange={(event) =>
                                                    handleThemAnh(event)
                                                }
                                            />
                                            <label
                                                htmlFor="banner"
                                                className="themAnh"
                                            >
                                                +
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="tenShop-container">
                                <div className="tieuDe">Tên Shop</div>
                                <input
                                    className="input"
                                    defaultValue={TenShop}
                                    type="text"
                                    onChange={(e) =>
                                        handleTenShop(
                                            e.target.value,
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                            <div className="tenShop-container">
                                <div className="tieuDe">Số Điện Thoại</div>
                                <input
                                    className="input"
                                    defaultValue={SdtShop}
                                    type="number"
                                    onChange={(e) => setSdtShop(e.target.value)}
                                />
                            </div>
                            <div className="diaChi">
                                <ChonDiaChi
                                    thonXom={thonXom}
                                    setthonXom={setthonXom}
                                    xa={xa}
                                    setxa={setxa}
                                    huyen={huyen}
                                    sethuyen={sethuyen}
                                    tinh={tinh}
                                    settinh={settinh}
                                />
                            </div>

                            <div className="toaDo-container">
                                <div className="toDo-viTri">
                                    <div className="toaDo">Toạ Độ</div>
                                    <div
                                        onClick={() => handleViTriHienTai()}
                                        className="viTri"
                                    >
                                        Vị Trí Hiện Tại
                                    </div>
                                </div>
                                <div className="kDvD-container">
                                    <div className="kinhDo-input">
                                        <input
                                            className="input"
                                            placeholder={kinhDo}
                                            onChange={(e) =>
                                                setkinhDo(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="kinhDo-input">
                                        <input
                                            className="input"
                                            placeholder={viDo}
                                            onChange={(e) =>
                                                setviDo(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="tenShop-container">
                                <div className="tieuDe">
                                    Gmail (Bắt buộc có để nhận thông báo nhanh
                                    !)
                                </div>
                                <input
                                    className="input"
                                    defaultValue={gmail}
                                    type="text"
                                    onChange={(e) => setgmail(e.target.value)}
                                />
                            </div>
                            <div className="tenShop-container">
                                <div className="tieuDe">Link Facebook</div>
                                <input
                                    className="input"
                                    defaultValue={faceBook}
                                    type="text"
                                    onChange={(e) =>
                                        setfaceBook(e.target.value)
                                    }
                                />
                            </div>
                            <div className="tenShop-container">
                                <div className="tieuDe">Số Zalo</div>
                                <input
                                    className="input"
                                    defaultValue={zalo}
                                    type="text"
                                    onChange={(e) => setzalo(e.target.value)}
                                />
                            </div>
                            <div className="tenShop-container">
                                <div className="tieuDe">Slogan</div>
                                <input
                                    className="input"
                                    defaultValue={slogan}
                                    type="text"
                                    onChange={(e) => setslogan(e.target.value)}
                                />
                            </div>
                            <div className="nganHang">
                                <div className="chonNganHang">
                                    Tài Khoản Ngân Hàng
                                </div>
                                <select
                                    id="provinces"
                                    onChange={(e) =>
                                        settenNganHang(e.target.value)
                                    }
                                >
                                    <option>{tenNganHang}</option>
                                    {danhSachNganHang?.map((item) => {
                                        return (
                                            <option key={item.maSo}>
                                                {item.tenNganHang}
                                            </option>
                                        );
                                    })}
                                </select>

                                <div className="stk-chuTk">
                                    <input
                                        className="stk"
                                        onChange={(e) =>
                                            settaiKhoanNganHang(e.target.value)
                                        }
                                        type="number"
                                        placeholder={taiKhoanNganHang}
                                    />
                                    <input
                                        onChange={(e) =>
                                            setchuTaiKhoanNganhang(
                                                e.target.value
                                            )
                                        }
                                        className="chuTk"
                                        placeholder={chuTaiKhoanNganhang}
                                    />
                                </div>
                            </div>
                            <div className="soBan-container">
                                <div className="soBan">Số Bàn</div>
                                <div className="sdt-them">
                                    <input
                                        id="input3"
                                        className="sdt"
                                        placeholder="Nhập Số Bàn"
                                        type="text"
                                        onChange={(e) =>
                                            setsoBan2(e.target.value)
                                        }
                                    />
                                    <div
                                        className="them"
                                        onClick={handleThemSoBan}
                                    >
                                        <i className="fa fa-plus"></i>
                                    </div>
                                </div>
                                {soBan &&
                                    soBan?.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="danhSach-xoa"
                                            >
                                                <div className="danhSach">
                                                    {item}
                                                </div>
                                                <div
                                                    className="xoa"
                                                    onClick={() =>
                                                        handleXoaSoBan(item)
                                                    }
                                                >
                                                    <i className="fa fa-close"></i>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                            <div className="tenShop-container">
                                <div className="tieuDe">Ngành Hàng</div>
                                <div className="boxSoanThao">
                                    <BoxSoanThao
                                        setvanBan={setvanBan}
                                        vanBan={vanBan}
                                    />
                                </div>
                            </div>
                            <div className="nganHang">
                                <div className="chonNganHang">
                                    Giao Diện Mặc Định
                                </div>
                                <select
                                    id="provinces"
                                    onChange={(e) =>
                                        setgiaoDien(e.target.value)
                                    }
                                >
                                    {giaoDien === "1" ? (
                                        <option value="1">
                                            Giao Diện Shop
                                        </option>
                                    ) : (
                                        <option value="-1">
                                            Giao Diện Blog
                                        </option>
                                    )}
                                    {giaoDien !== "-1" && (
                                        <option value="-1">
                                            Giao Diện Blog
                                        </option>
                                    )}
                                    {giaoDien !== "1" && (
                                        <option value="1">
                                            Giao Diện Shop
                                        </option>
                                    )}
                                </select>
                            </div>
                            <div className="nganHang">
                                <div className="chonNganHang">Xuất Bản</div>
                                <select
                                    id="provinces"
                                    onChange={(e) => setxuatBan(e.target.value)}
                                >
                                    <option>{xuatBan}</option>
                                    {xuatBan !== "Xây Dựng" && (
                                        <option>Xây Dựng</option>
                                    )}
                                    {xuatBan !== "Hoàn Thành" && (
                                        <option>Hoàn Thành</option>
                                    )}
                                </select>
                            </div>
                            {user?.admin === true && (
                                <div className="admin-container">
                                    <div className="admin">
                                        <div className="tieuDe">
                                            Số ID Chủ Shop
                                        </div>
                                        <input
                                            className="input"
                                            defaultValue={UserShop}
                                            type="text"
                                            onChange={(e) =>
                                                setUserShop(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="admin">
                                        <div className="tieuDe">
                                            Người Hỗ Trợ
                                        </div>
                                        <input
                                            className="input"
                                            defaultValue={nguoiHoTro}
                                            type="text"
                                            onChange={(e) =>
                                                setnguoiHoTro(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="admin">
                                        <div className="tieuDe">
                                            Fabysa Xanh
                                        </div>
                                        <input
                                            className="input"
                                            defaultValue={cash}
                                            type="text"
                                            onChange={(e) =>
                                                setcash(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="admin">
                                        <div className="tieuDe">
                                            Nguời Hỗ Trợ
                                        </div>
                                        <input
                                            className="input"
                                            placeholder={nguoiHoTro}
                                            type="text"
                                            onChange={(e) =>
                                                setnguoiHoTro(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="admin">
                                        <div className="tieuDe">Hiển thị</div>
                                        <input
                                            className="input"
                                            defaultValue={capBac}
                                            type="number"
                                            onChange={(e) =>
                                                setcapBac(e.target.value)
                                            }
                                        />
                                        <div>
                                            Ghi Chú: 1-Hiển thị / 2-Không hiển
                                            thị
                                        </div>
                                    </div>
                                </div>
                            )}

                            {(user?._id === ttShop?.user ||
                                user?.admin === true ||
                                nvQuanLy?.find(
                                    (item) =>
                                        item?.sdtnvQuanLy === user?.username
                                )) && (
                                <div
                                    className="luu"
                                    onClick={handleLuuThongTinShop}
                                >
                                    Lưu Thông Tin
                                </div>
                            )}
                        </div>
                        {TTLoading === true && <Loading />}
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="UpdateShop-ConTaiNer">UpdateShop</div>
                </div>
            )}
        </div>
    );
};
export default UpdateShop;
