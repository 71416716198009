import "./AddShop.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { registerttShop } from "../redux/apiRequest";
import Loading from "../GiaoDienChung/Loading";
import ChonDiaChi from "../component/ChonDiaChi";
const AddShop = (props) => {
    const user = useSelector((state) => state?.auth?.login?.currentUser);
    const TTLoading = useSelector((state) => state?.ttShop?.ttShop?.isFetching);
    const message = useSelector(
        (state) => state?.ttShop?.ttShop?.ttShop?.message
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [TenShop, setTenShop] = useState();
    const [SdtShop, setSdtShop] = useState();
    const [UserShop, setUserShop] = useState(user?._id);

    const [thonXom, setthonXom] = useState("Thôn/Xóm/Số nhà...");
    const [xa, setxa] = useState("Xã/Phường");
    const [huyen, sethuyen] = useState("Quận/Huyện");
    const [tinh, settinh] = useState("Tỉnh/TP");
    const handleLuuThongTinShop = () => {
        if (!message || message !== "Tao moi thanh Cong") {
            const menuShop = [
                "Khuyến Mại Đặc Biệt",
                "Điện Thoại",
                "Quần Áo",
                "Đồng Hồ ",
                "Hoa Quả",
            ];
            const nvBanHang = [{ sdt: SdtShop, ten: "Chủ Shop" }];
            const nvQuanLy = [{ sdt: SdtShop, ten: "Chủ Shop" }];
            const soBan = ["Bàn 1", "Bàn 2", "Bàn 3"];
            if (!TenShop || !SdtShop || !tinh || !huyen) {
                alert("Vui lòng nhập đủ thông tin");
            } else {
                try {
                    const newShop = {
                        TenShop: TenShop,
                        sdtShop: SdtShop,
                        tinh: tinh,
                        huyen: huyen,
                        xa: xa || "Trống",
                        thonXom: thonXom || "Trống",

                        cash: 300000,
                        phiNenTang: "1K/Đơn Hàng",
                        capBac: 1,
                        xuatBan: "Xây Dựng",
                        ttShopThem: {
                            menuShop,
                            giaoDien: "1",
                            nvBanHang,
                            nvQuanLy,
                            soBan,
                            tenVietTat: "ten-ngan",
                        },
                        allAnhShop: [],
                        user: UserShop || user._id,
                    };
                    console.log("newShop", newShop);
                    registerttShop(newShop, dispatch);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    };
    return (
        <div className="addShop-container">
            <div className="quayLai-tieuDe">
                <a href={`/ca-nhan/a/a/a/a/a/a`} className="quayLai">
                    <i className="fa fa-angle-double-left"></i>Quay Lại
                </a>
                <div className="tieuDe">Thêm Shop Mới</div>
            </div>
            <div className="addShop">
                <div className="tenShop-TS">
                    <div className="tenShop">Tên Shop</div>
                    <input
                        className="TS"
                        placeholder="Nhập Tên Shop"
                        type="text"
                        onChange={(e) => setTenShop(e.target.value)}
                    />
                </div>

                <div className="tenShop-TS">
                    <div className="tenShop">Số Điện Thoại</div>
                    <input
                        className="TS"
                        placeholder="Nhập Số Điện Thoại"
                        type="text"
                        onChange={(e) => setSdtShop(e.target.value)}
                    />
                </div>

                <div className="diaChi">
                    <ChonDiaChi
                        thonXom={thonXom}
                        setthonXom={setthonXom}
                        xa={xa}
                        setxa={setxa}
                        huyen={huyen}
                        sethuyen={sethuyen}
                        tinh={tinh}
                        settinh={settinh}
                    />
                </div>
                <div className="luuY">
                    Shop mới được tặng 300.000&#160;<span>F&#160;</span>
                </div>
                {!message || message !== "Tao moi thanh Cong" ? (
                    <div className="luuShop" onClick={handleLuuThongTinShop}>
                        Thêm Shop Mới
                    </div>
                ) : (
                    <div className="daluuShop">Đã Thêm Mới</div>
                )}
            </div>
            {TTLoading === true && <Loading />}
        </div>
    );
};
export default AddShop;
